@import 'site:variables';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

.item-group.performance-tables {
    @include grid-justify-self(center);
    margin-left: $gutter;
    margin-right: $gutter;
    width: calc(100% - #{$gutter * 2});
    max-width: 1200px;
    & > .item-wrapper {
        border-bottom: 1px solid $light-gray;
        padding-bottom: $baseline / 2;
    }
    .item-root {
        margin-top: $baseline * 2;
    }
    .performance-table-categories {
        display: flex;
        flex-wrap: wrap;
    }
    .performance-table-category {
        @include typography(header-d);
        @include button-color(outline);
        white-space: normal;
        &.is-active {
            @include button-color(fill);
        }
    }
    .performance-table-types {
        display: flex;
        flex-wrap: wrap;
        margin-top: $baseline * 2;
    }
    .performance-table-type {
        @include button-color(text-only);
        white-space: normal;
        &.is-active {
            border-bottom-color: $medium-gray; 
        }
    }
    .performance-table-item {
        margin-top: $baseline;
        overflow: auto;
        svg {
            min-width: 800px;
        }
    }
}
