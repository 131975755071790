@mixin callouts-grid() {
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                smallplus: 2,
                medium: 3,
                large: 4
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true
        );
    }
    @include callouts-grid-item;
}
@mixin callouts-grid-item {
    .item {
        display: flex;
        background-color: $white;
    }
    a.item,
    a.head-link {
        color: inherit;
        box-shadow: inset 0 0 0 $primary-color;
        transition: box-shadow .3s ease;
        &:hover,
        &:focus {
            box-shadow: inset 0 -4px 0 $primary-color;
        }
        .title {
            @include button-arrow($text-color-regular);
        }
    }
    .head-link {
        display: flex;
        flex-grow: 1;
    }
    .head {
        flex-grow: 1;
    }
    .image {
        @include object-fit;
        position: relative;
        max-height: 360rem / $em;
        &::before {
            content: '';
            display: block;
            padding-bottom: percentage(3/4);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg {
            position: absolute;
            top: 15%;
            left: 15%;
            width: 70%;
            height: 80%;
            fill: $medium-gray;
        }
    }
    .text-group {
        padding: $baseline * 1.5;
        & > * {
            margin-top: $baseline / 2;
            &:first-child {
                margin-top: 0;
            }
        }
        .title {
            @include typography(header-d);
        }
        .summary {
            @include typography(text-small);
        }
    }
    .button.more {
        @include button-color(text-only, false);
        @include button-arrow($text-color-regular);
        display: block;
        width: 100%;
        text-align: right;
        margin-top: $baseline * -2;
        padding: $baseline * 1.5;
    }
}

@mixin callouts-grid-item-diagram-row() {
    .head {
        flex-direction: row;
        justify-content: flex-start;
        .image {
            width: $baseline * 5;
            flex-grow: 0;
            flex-shrink: 0;
            &::before {
                padding-bottom: 100%;
            }
            img {
                height: 80%;
            }
            svg {
                height: 70%;
            }
        }
        .text-group {
            flex-grow: 1;
        }
        .image + .text-group {
            padding-left: $baseline / 2;
        }
    }
}

@mixin callouts-grid-item-diagram($row-breakpoint: small) {
    .head {
        display: flex;
        flex-direction: column;
        .image {
            img {
                object-fit: contain;
                top: 10%;
                left: 10%;
                width: 80%;
                height: 90%;
            }
        }
    }
    @if $row-breakpoint {
        @include breakpoint($row-breakpoint down) {
            @include callouts-grid-item-diagram-row();
        }
    }
}

@mixin callouts-alternating() {
    .head-image {
        position: relative;
        .image {
            @include object-fit;
            position: relative;
            padding-bottom: percentage(9/16);
            background-color: $light-gray;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        .button {
            @include button-color(white);
            position: absolute;
            right: $gutter;
            bottom: $gutter;
        }
        @include breakpoint(medium up) {
            margin: 0 $gutter;
            .image {
                padding-bottom: percentage(3/4);
            }
            .button {
                top: $gutter;
                left: $gutter;
                right: auto;
                bottom: auto;
            }
        }
    }
    .head-image.embed {
        cursor: pointer;
        .image,
        .video {
            position: relative;
            padding-bottom: percentage(9/16);
        }
        .image {
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 30%;
                padding-bottom: 30%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                opacity: .7;
                @include sb-icon-play-arrow($white);
                background-color: $black;
                background-size: 50% 50%;
                background-position: 64% 50%;
                background-repeat: no-repeat;
                transition: opacity .2s;
            }
        }
        .video {
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &:hover,
        &:focus {
            .image::after {
                opacity: .9;
            }
        }
    }
    .background-image {
        display: none;
        position: relative;
        .image {
            @include object-fit;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        @include breakpoint(medium up) {
            display: block;
        }
    }
    .text-group {
        position: relative;
        @include breakout($outer-gutters) {
            margin-left: $value + $gutter;
            margin-right: $value + $gutter;
        }
        @include breakout($section-padding) {
            margin-top: $value;
            margin-bottom: $value;
        }
        @include breakpoint(medium up) {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: $gutter;
            margin-right: $gutter;
        }
        // @include breakpoint(large up) {
        //     padding-left: $gutter * 2;
        //     padding-right: $gutter * 2;
        // }
        & > * {
            margin-top: $baseline * 2;
            &:first-child {
                margin-top: 0;
            }
        }
        .pre {
            white-space: pre-line;
        }
        .subtitle {
            @include typography(header-e);
            color: $primary-color;
            & + .title {
                margin-top: $baseline / 2;
            }
        }
        .title {
            @include typography(header-b);
        }
        .text {
            // margin-top: $baseline * 2;
        }
        & > .button {
            @include button-arrow($text-color-white);
            margin-top: $baseline * 3;
        }
    }
    .item {
        @include grid14;
        
        @include grid-template-rows(0 auto 0);
        @include breakout($section-padding, medium) {
            margin-top: $value;
            // @include grid-template-rows($value auto $value);
        }
        &.has-background {
            @include breakout($section-padding, medium) {
                @include grid-template-rows($value auto $value);
            }
        }
        @include breakpoint(medium up) {
            @include grid;
            .background-image {
                z-index: 1;
                @include grid-row(1, 3);
                @include grid-column(5, 10);
                @include grid-align-self(stretch);
            }
            .head-image,
            .text-group {
                z-index: 3;
                @include grid-row(2);
                @include grid-align-self(center);
            }
            .head-image {
                @include grid-column(7, 6, true);
            }
            .text-group {
                @include grid-column(1, 6, true);
            }
            &::before {
                z-index: 2;
                position: relative;
                @include grid-column(1, 7);
            }
            &.has-image {
                &::before {
                    @include grid-column(1, 11);
                }
            }
            &.has-background {
                &::before {
                    content: '';
                    @include grid-row(2);
                    display: block;
                    background-color: $white;
                }
                .head-image {
                    @include grid-column(7, 4, true);
                    padding: $gutter;
                }
                .text-group {
                    @include breakout($section-padding, medium) {
                        padding-top: $value;
                        padding-bottom: $value;
                    }
                }
            }
        }
        @include breakpoint(xxlarge up) {
            .text-group {
                @include grid-column(1, 5, true)
            }
        }
    }
    .item:nth-child(even) {
        .background-image {
            @include grid-column(1, 10);
        }
        .head-image {
            @include grid-column(1, 6, true);
        }
        .text-group {
            @include grid-column(7, 6, true);
            
        }
        &::before {
            @include grid-column(8, 7);
        }
        &.has-image {
            &::before {
                @include grid-column(4, 11);
            }
        }
        &.has-background {
            .head-image {
                @include grid-column(3, 4, true);
            }
        }
        @include breakpoint(xxlarge up) {
            .text-group {
                @include grid-column(8, 5, true);
            }
        }
    }
    .item.no-image:not(.has-background) {
        @include breakout($section-padding, medium) {
            @include grid-template-rows($value / 2 auto $value / 2);
        }
        .text-group {
            @include grid-column(1, 12, true);
            text-align: left;
        }
        @include breakpoint(xlarge up) {
            .text-group {
                @include grid-column(2, 10, true);
            }
        }
    }
}

@mixin callouts-testimonials {
    @include callouts-testimonials-item;
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                xlarge: 3
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true
        );
        justify-content: center;
    }
}
@mixin sb-icon-quote($svg-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="fill:#{$svg-color};"><path d="M17.2,9.7C9.9,9.7,4,15.7,4,22.9s5.9,13.2,13.2,13.2c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.8,0.9,1.1,1.2c3-3,13.2-14,11.9-30.9C29.9,15.3,24.3,9.7,17.2,9.7z"/><path d="M59.9,23c-0.6-7.8-6.1-13.4-13.2-13.4c-7.3,0-13.2,5.9-13.2,13.2S39.4,36,46.7,36c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.9,0.9,1.2,1.2C51,51,61.2,40,59.9,23L59.9,23z"/></svg>');
}

@mixin callouts-testimonials-item(
    $item-background: $white,
    $quote-color: $light-gray
) {
    .item,
    .head {
        display: flex;
    }
    .head {
        flex-direction: column;
        position: relative;
        width: 100%;
        background-color: $item-background;
        box-shadow: -3rem 0 $item-background,
                    3rem 0 $item-background;
        color: $text-color-regular;
        padding-bottom: $baseline * 3;
        &::before {
            @include sb-icon-quote($quote-color);
            content: '';
            display: block;
            width: 4em;
            height: 4em;
            margin-top: $baseline * 2;
            margin-left: auto;
            background-size: 4em 4em;
            background-repeat: no-repeat;
            background-position: center;
        }
        & > * {
            margin-top: $baseline * 2;
        }
        @include breakpoint(smallplus up) {
            box-shadow: none;
            padding-left: $baseline * 1.5;
            padding-right: $baseline * 1.5;
        }
    }
    .summary {
        line-height: 1.75;
        font-style: italic;
        margin-bottom: auto;
    }
    .title {
        @include typography(header-c);
    }
}

@mixin callouts-portfolio {
    overflow: hidden;
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                large: 3,
            ),
            $baseline: $baseline / 2,
            $gutter: $baseline / 2,
            $shrink-wrap: true,
        );
        & > * {
            flex-grow: 1;
        }
    }
    @include callouts-portfolio-item();
}

@mixin callouts-portfolio-item(
    $height: (
        small: 360rem/$em,
        xlarge: 480rem/$em,
    ),
) {
    .item {
        display: flex;
        background-color: $white;
        position: relative;
    }
    a.item {
        color: inherit;
        .title {
            @include button-arrow($text-color-white);
        }
        &::after {
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 0 0 $primary-color;
            transition: box-shadow .3s ease;
        }
        &:hover,
        &:focus {
            &::after {
                box-shadow: inset 0 -4px 0 $primary-color;
            }
        }
    }
    .head {
        flex-grow: 1;
    }
    .image {
        @include object-fit;
        @include breakout($height) {
            height: $value;
        }
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .text-group {
        padding: $baseline $baseline * 1.5;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 100%;
        background-color: rgba($medium-gray, .9);
        color: $text-color-white;
        & > * {
            margin-top: $baseline / 2;
            &:first-child {
                margin-top: 0;
            }
        }
        .title {
            @include typography(header-d);
            color: inherit;
        }
        .summary {
            @include typography(text-small);
        }
    }
}
