@mixin hero-banner-height($height, $portrait-height, $page-padding) {
    @include breakout($height) {
        min-height: calc(#{$value} - var(--header-height));
    }
    @media (orientation: portrait) {
        @include breakout($portrait-height) {
            min-height: calc(#{$value} - var(--header-height));
        }
    }
    .hero-page {
        @include breakout($page-padding) {
            padding-bottom: $value;
        }
        .breadcrumbs {
            @include breakout($page-padding) {
                margin-bottom: calc(#{$value} - #{$baseline * 2});
            }
        }
    }
}
@mixin hero-banner($height: 40vh, $portrait-height: 30vh, $page-padding: $baseline * 3) {
    $banner-outer-gutters: ();
    @each $breakpoint, $value in $outer-gutters {
        $banner-outer-gutters: map-merge($banner-outer-gutters, (#{$breakpoint}: $value * 2/3));
    }
    @include grid;
    @include grid14($outer-gutters: $banner-outer-gutters);
    @include grid-template-rows(1fr);
    @include hero-banner-height($height, $portrait-height, $page-padding: $page-padding);
    background-color: $white;
    .page_image {
        @include grid-column(1, 14);
        @include grid-row(1);
        @include object-fit();
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .hero-page {
        @include grid-column(1, 14);
        @include grid-justify-self(stretch);
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: $baseline * 2;
        background-color: rgba($primary-color, .9);
        background: linear-gradient(
            rgba($primary-color-dark, .8),
            rgba($primary-color, .8),
        );
        color: $text-color-white;
        @include breakout($banner-outer-gutters) {
            padding-left: $value + $gutter;
            padding-right: $value + $gutter;
        }
        @include breakpoint(medium up) {
            @include grid-column(1, 8);
        }
        @include breakpoint(large up) {
            @include grid-column(1, 6, true);
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
            margin-left: $gutter * -1;
            margin-right: $gutter * -1;
        }
        @include breakpoint(xxlarge up) {
            @include grid-column(1, 5, true);
        }
        .button {
            @include button-color(white-outline);
            @include button-arrow($text-color-white);
        }
        .breadcrumbs {
            margin-top: 0;
            & + * {
                margin-top: auto;
            }
        }
        .rte a {
            color: inherit;
        }
    }
    .page_image ~ .hero-page {
        align-self: stretch;
    }
}

@mixin paper {
    background-color: $white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .20),
        0px 1px 1px 0px rgba(0, 0, 0, .14),
        0px 1px 3px 0px rgba(0, 0, 0, .12);
}
