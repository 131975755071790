@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';
@import '@depot/rte-content';

@import 'site:item-mixins';
@import 'forms:mixins';

@import 'callouts:mixins';
@import 'slideshow:mixins';
@import 'mixins';

.layout-portfolio-page {
    @include page-layout-portfolio-page;
}

.gallery-list-cont {
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    .categories-select {
        .input {
            margin: 0;
            width: auto;
        }
    }
    .gallery-grid {
        flex-basis: calc(100% - #{$gutter * 2});
        &:not(:first-child) {
            margin-top: $baseline;
        }
    }
}

.item-group.gallery-grid {
    @include callouts-grid-item;
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                large: 3,
                xxlarge: 4
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true,
        );
    }
}

.item-group.case-study-slideshow {
    $slideshow-sizes: ();
    $slideshow-portrait-sizes: 40vh;
    @include breakout($header-height) {
        $slideshow-sizes: map-merge($slideshow-sizes, ($breakpoint: calc(100vh - #{$value} - #{$baseline * 7})));
        // $slideshow-portrait-sizes: map-merge($slideshow-portrait-sizes, ($breakpoint: calc(60vh - #{$value} - #{$baseline * 6})));
    }
    @include slideshow;
    @include slideshow-enhance(contain);
    @include slideshow-size($slideshow-sizes, $slideshow-portrait-sizes);
    // .slideshow-stage {
    //     background-color: $black;
    // }
    background-color: $white;
    @include breakout($section-padding, large) {
        box-shadow: 0 $value $white;
    }
    .item {
        background-color: $white;
    }
    .slideshow-navigation {
        @include slideshow-navigation-images(
            $thumbnail-size: (
                small: $baseline * 4,
                medium: $baseline * 6
            ),
            $gutter: (
                small: #{2 / $em}rem,
            ),
            $align: start,
            $thumbnail-styles: (
                transition: unquote("filter .1s, box-shadow .2s"),
                box-shadow: 0 0 0 0 $primary-color,
            ),
            $current-styles: (box-shadow: 0 3px 0 0 $primary-color),
            $hover-styles: (filter: brightness(1.2)),
        );
        ul {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
        }
        li {
            flex-shrink: 0;
            .image {
                overflow: visible;
            }
        }
    }
}

.item-group.case-study-content {
    @include case-study-content;
}

.item-group.configuration-grid-compact {
    @include callouts-grid-item;
    @include callouts-grid-item-diagram($row-breakpoint: false);
    .items {
        display: flex;
        flex-wrap: wrap;
        & > * {
            width: 96rem / $em;
            margin-top: $baseline / 2;
            margin-right: $baseline / 2;
        }
        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(96rem / $em, 1fr));
            grid-gap: $baseline / 2;
            & > * {
                margin: 0;
                width: 100%;
            }
        }
    }
    .head {
        justify-content: center;
        text-align: center;
    }
    .text-group {
        padding: $baseline * 2/3 $baseline / 4;
        .title {
            font-size: (12rem/$em);
            line-height: 1.25;
        }
    }
}

.item-group.single-pagination {
    .next-button {
        display: flex;
        padding: $baseline;
        max-width: (480rem / $em);
        background-color: $paper-background-color;
        color: inherit;
        box-shadow: inset 0 0 0 $primary-color;
        transition: box-shadow .3s ease;
        &:hover,
        &:focus {
            box-shadow: inset 0 -4px 0 $primary-color;
        }
    }
    .image {
        @include object-fit;
        position: relative;
        flex-basis: 25%;
        flex-shrink: 0;
        margin-right: $baseline * 1.5;
        &::before {
            content: '';
            display: block;
            padding-bottom: percentage(3/4);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .text-group {
        align-self: center;
    }
    .subtitle {
        @include typography(header-d);
    }
    .title {
        @include typography(header-c);
        @include button-arrow($text-color-regular);
    }
}
