@import 'site:variables';
@import 'site:icon-mixins';
@import '@depot/breakpoint';
@import '@depot/utility';
@import '@depot/typography';
@import 'mixins';

.item-group.slideshow {
    @include slideshow;
    @include slideshow-enhance;
    @include slideshow-size(65vh, 50vh);
}

.item-group.split-slideshow {
    @include split-slideshow;
}
