@import 'site:variables';

@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/grid';

.layout-news-list {
    .lister.article-list {
        @include breakpoint(xlarge up) {
            @include grid-column(2, 10, true);
        }
    }
}

.layout-news-page {
    .item-group.page {
        @include grid-justify-self(center);
        @include typography(text-large);
        width: calc(100% - #{$gutter * 2});
        max-width: $content-width;
        margin-left: $gutter;
        margin-right: $gutter;
    }
    .callouts-grid {
        padding-top: $baseline * 2;
        border-top: 1px solid $light-gray;
    }
    .item-group.single-pagination {
        margin-left: $gutter;
        margin-right: $gutter;
        .next-button {
            margin-left: auto;
        }
    }
}
