@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';

.layout-embed_form-page {
    // jot forms are supposed to have whitespace all around, but don't currently have
    // a bottom margin due to a margin collapse bug on their end. Uncommonent this if they fix that

    // .primary {
    //     padding: 0;
    // }
    .controller.html {
        @include grid-column(1, 14);
        margin: 0;
    }
}
