@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

// @import 'site:item-mixins';
// @import 'forms:mixins';

@import 'mixins';

.layout-contact-page {
    .primary {
        padding-bottom: 0;
    }
}

.item-group.contact-group {
    margin-top: 0;
    @include grid;
    @include grid14;
    @include grid-column(1, 14);
    & > * {
        @include grid-column(1, 12, true);
        margin-left: $gutter;
        margin-right: $gutter;
    }
    .contact-information {
        @include grid-row(1);
        @include breakout($section-padding) {
            margin-top: $value;
        }
    }
    .contact-form {
        @include grid-row(2);
        @include grid-justify-self(start);
        @include breakout($section-padding) {
            margin-top: $value;
        }
    }
    .contact-map {
        @include grid-row(3);
        @include grid-column(1, 14);
        margin-left: 0;
        margin-right: 0;
        @include breakout($section-padding) {
            margin-top: $value;
        }
    }

    @include breakpoint(medium up) {
        .contact-information {
            @include grid-row(1);
            @include grid-column(1, 6, true);
        }
        .contact-form {
            @include grid-row(1);
            @include grid-column(7, 6, true);
        }
    }
}

.item-group.contact-form {
    @include contact-form($form-icon-color: $gray400);
    .item-wrapper {
        margin-bottom: $baseline * 2;
    }
}
