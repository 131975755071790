@import 'input-label-absolute';

@function get-input-label-placeholder-props($props: ()) {
    @return get-input-label-absolute-props($props);
}

@mixin input-label-placeholder-colors(
    $props: ()
) {
    $props: get-input-props($props, input-colors);

    @include input-label-absolute-colors($props);

    input,
    textarea {
        &:not(:focus):not(.has-value) + label {
            color: map-get($props, placeholder-text-color);
            &::before {
                background-color: map-get($props, inner-background-color);
            }
        }
    }
}
@mixin input-label-placeholder(
    $props: ()
) {
    $props: get-input-props($props, input-label-placeholder);
    @include input-label-absolute($props);

    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);

    $label-font-size: map-get($props, label-font-size);
    $label-x-offset: map-get($props, label-x-offset); 
    $label-padding: map-get($props, label-padding); 
    $input-border-width: get-border-value(map-get($props, input-border), width);
    $input-padding: map-get($props, input-padding);
    $input-line-height: map-get($props, input-line-height);
    $input-font-size: map-get($props, input-font-size);

    #{$label-selector} {
        position: absolute;
        pointer-events: none;
        transition: all .3s;
    }
    #{$field-selector} {
        &:not(:focus):not(.has-value) + label {
            margin-top: get-side($input-padding, top);
            margin-left: calc(#{get-side($input-padding, left)} - #{$label-padding});
            font-size: $input-font-size;
            line-height: $input-line-height;
        }
    }
}