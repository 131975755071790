@import 'site:variables';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';

.item-group.contact-map {
    background-color: $paper-background-color;
    .item-root {
        @include grid;
        @include grid14;
    }
    .contact-list-map {
        display: flex;
        @include grid-column(1, 14);
        @include breakpoint(medium up) {
            display: flex;
        }
        @include breakpoint(large up) {
            @include grid-column(1, 7);
            @include grid-align-self(start);
        }
    }
    .contact-list-contacts {
        @include grid-column(1, 12, true);
        @include breakout($section-padding) {
            padding-top: $value;
            padding-bottom: $value;
        }
        margin-left: $gutter;
        margin-right: $gutter;
        @include breakpoint(large up) {
            @include grid-column(7, 6, true);
        }
    }
    .contact-list-contacts-header {
        h2.title {
            @include typography(header-c);
        }
        .description {
            margin-top: $baseline / 2;
        }
        @include breakpoint(large up) {
            @include grid;
            @include grid-template-columns(auto auto);
            @include grid-template-rows(auto 1fr);
            h2.title {
                @include grid-row(1);
                @include grid-column(1);
            }
            .description {
                @include grid-row(2);
                @include grid-column(1, 2);
            }
            .input-region {
                @include grid-row(1);
                @include grid-column(2);
                margin-top: 0;
            }
        }
        @include breakpoint(xlarge up) {
            .description {
                @include grid-row(2);
                @include grid-column(1);
            }
            .input-region {
                @include grid-row(1, 2);
                @include grid-column(2);
            }
        }
    }
    .contact-list-region {
        stroke: white;
        stroke-width: .5px;
        transition: fill .2s, filter .2s;
        &:focus {
            outline: none;
        }
    }
    .contact-list-has-contacts {
        cursor: pointer;
        &:hover {
            filter: saturate(1.5);
        }
    }
    .no-contacts {
        margin-top: $baseline * 3;
        font-size: 1.5em;
        opacity: .5;
    }
    .contact-list-contact {
        @include grid;
        @include grid-template-columns(1fr 1fr);
        @include grid-template-rows(auto 1fr);
        margin-top: $baseline * 2;
        padding-top: $baseline * 2;
        border-top: 1px solid $light-gray;
        h3.title {
            @include grid-row(1);
            @include grid-column(1, 2);
        }
        h4.title {
            text-transform: none;
        }
        .subtitle {
            @include grid-row(2);
            @include grid-column(1);
        }
        .categories {
            @include grid-row(2);
            @include grid-column(2);
            align-self: end;
            margin-left: 1em;
            span:not(:last-child)::after {
                content: ',';
                margin-right: .5em;
            }
        }
        .address {
            @include grid-row(3);
            @include grid-column(1, 2);
            margin-top: $baseline;
        }
        .options {
            @include unstyled-list;
            display: flex;
            flex-wrap: wrap;
            .option {
                margin-top: $baseline / 2;
                margin-right: 2em;
                *:first-child {
                    color: $text-color-dark;
                }
                span:first-child {
                    text-transform: uppercase;
                    font-size: (12rem / $em);
                    font-weight: $bold;
                }
                * + * {
                    margin-left: .5em;
                }
            }
        }
        @include breakpoint(medium up) {
            h3.title {
                @include grid-column(1);
            }
            // .subtitle {
            //     grid-column: 1;
            // }>
            .categories {
                @include grid-row(1, 2);
                @include grid-column(2);
                @include grid-align-self(end);
            }
        }
    }
}
