.hero-page {
    .subtitle {
        @include typography(header-e);
    }
    .text,
    .description {
        @include typography(text-large);
        margin-top: $baseline * 2;
    }
    .link-button {
        margin-top: $baseline * 3;
    }
}

.item-group.breadcrumbs {
    @include typography(text-small);
    color: inherit;
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: $baseline * 2;
    }
    li {
        display: flex;
        line-height: 1.2;
    }
    li:not(:last-child) {
        margin-right: .5em;
    }
    li:not(:first-child)::before {
        content: '/';
        margin-right: .5em;
    }
    a {
        color: inherit;
        &:hover,
        &:focus {
            // color: $off-white;
            text-decoration: underline;
        }
    }
}

.item-group.sitemap {
    @include sitemap-basic;
    margin-left: $gutter;
    margin-right: $gutter;
    a {
        @include typography(header-d);
        &:hover,
        &:focus {
            color: $anchor-color;
        }
    }
}

.item-group.pagination-page {
    @include pagination;
    padding-top: $baseline;
    border-top: 1px solid;
    ul {
        width: 100%;
        &::before {
            flex-grow: 1;
        }
    }
}
