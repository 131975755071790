@import 'site:variables';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

@import 'site:page-mixins';
@import 'site:item-mixins';

.layout-home-home {
    @include page-layout-feature;
    .item-wrapper:not(:empty) {
        & > * {
            margin-top: $baseline * 1.5;
            max-width: 42rem;
            &:first-child {
                margin-top: 0;
            }
            @include breakpoint(large up) {
                margin-top: $baseline * 2;
            }
        }
        @include breakout($section-padding) {
            margin-bottom: $value / 2;
        }
    }
    .lister.callouts-grid {
        .items > * {
            flex-grow: 1;
            @include breakpoint(large up) {
                @include flex-column($column-count: 3);
            }
            @include breakpoint(xlarge up) {
                @include flex-column($column-count: 5);
            }
        }
    }
}
