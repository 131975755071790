@import 'site:variables';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/typography';

@import 'mixins';

.finish-gallery {
    @include finish-gallery;
}

.item-group.finishes {
    @include grid-column(1, 14);
    .item-wrapper {
        @include grid;
        @include grid14;
        @include breakpoint(medium up) {
            margin-bottom: $baseline * 2;
        }
        .title {
            @include grid-column(1, 12, true);
            border-bottom: 1px solid $light-gray;
            padding-bottom: $baseline / 2;
            margin-left: $gutter;
            margin-right: $gutter;
        }
    }
    .item:not(:first-child) {
        @include breakout($section-padding) {
            margin-top: $value;
        }
    }
    .item:nth-child(even) .finish-gallery {
        @include finish-gallery-alternate;
    }
}
