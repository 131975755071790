.header {
    z-index: 29;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $header-background-color;
    box-shadow: 0 0 2px rgba($black, .2);
    .logo img {
        width: auto;
        @include breakout($header-logo-height) {
            height: $value;
        }
    }
    @media (min-height: 400px) {
        position: sticky;
    }
}
.header-inner {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: $medium;
    @include breakout($header-height) {
        min-height: $value;
    }
    padding-left: $gutter;
    padding-right: $gutter;
    
    .logo {
        margin-left: $gutter;
        margin-right: $gutter;
    }

    @include breakpoint($mobile-breakpoint-up up) {
        padding-left: $gutter * 2;
        padding-right: $gutter * 2;
    }
}
.primary-navigation {
    display: flex;
    a {
        display: flex;
    }
    .nav-primary {
        a {
            @include typography(header-e);
            color: $text-color-regular;
            transition: color .2s;
        }
        a:not(.is-active):hover {
            color: $anchor-color;
        }
        li[class*="nav-name-product"] {
            a {
                @include typography(header-d);
                font-size: 1rem;
                color: $text-color-dark;
            }
        }
        .nav-name-request-quote > a {
            @include apply-input-mixin(button-base);
            @include button;
            display: flex;
        }
        .nav-name-request-quote.nav-name-request-quote > a {
            @include button-color(fill);
            box-shadow: none;
        }
    }
    @include breakpoint($mobile-breakpoint-down down) {
        flex-direction: column;
        background-color: $white;
        box-shadow: 0 0 2px rgba($black, .2);
        .search-cont {
            order: 1;
            margin-left: $baseline * 2;
            margin-right: $baseline * 2;
            margin-top: $baseline;
        }
        .nav-quote-request {
            order: 2;
            margin-top: $baseline * 1.5;
            margin-left: $baseline * 2;
            align-self: flex-start;
        }
        .nav-primary {
            display: flex;
            flex-direction: column;
            order: 3;
            height: 0;
            flex-grow: 1;
            overflow-y: auto;
            padding: $baseline 0;
            a,
            .nav-name-request-quote a {
                display: block;
                padding: $baseline $baseline * 5/3;
            }
            & > .is-active > a,
            & > .is-active > .sub-menu {
                box-shadow: inset $baseline / 3 0 $anchor-color;
            }
        }
        .sub-menu {
            display: none;
            padding-left: $baseline;
            & > .is-active > a::before {
                content: '';
                display: inline-block;
                width: .5em;
                height: .5em;
                background-color: $anchor-color;
                margin-left: -1em;
                margin-right: .5em;
                border-radius: 50%;
                margin-bottom: .125em;
            }
        }
        .nav-name-request-quote {
            order: -1;
            margin-bottom: $baseline;
        }
        .is-active > .sub-menu {
            display: block;
        }
    }

    @include breakpoint($mobile-breakpoint-up up) {
        @include flyout-menu-full(
            $primary-text-color: $text-color-regular,
            $primary-background-color: nth($gray, 1),
            $secondary-text-color: $text-color-dark,
            $secondary-background-color: nth($gray, 2),
        );
        align-self: stretch;
        flex-grow: 1;
        .nav-primary {
            display: flex;
            flex-grow: 1;
            margin-left: $baseline * 2;
            li {
                display: flex;
            }
            a {
                display: flex;
                align-items: center;
                padding-left: .5em;
                padding-right: .5em;
            }
            .has-sub-menu > a {
                pointer-events: none;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 6rem / $em;
                    height: 6rem / $em;
                    margin-left: .5em;
                    margin-top: -3rem / $em;
                    transform: rotate(45deg);
                    border-right: 2rem / $em solid;
                    border-bottom: 2rem / $em solid;
                }
            }
            .sub-menu .has-sub-menu > a::after {
                margin-top: 2rem / $em;
                border-bottom: none;
                border-top: 2rem / $em solid;
            }
            & > .is-active {
                box-shadow: inset 0 -2px $anchor-color;
            }
            .has-sub-menu.is-active > a::after {
                border-color: $anchor-color;
            }
            .sub-menu .is-active:not(.has-sub-menu) > a {
                color: $anchor-color;
            }
            li[class*="nav-name-product"] {
                & + li:not([class*="nav-name-product"]) {
                    margin-left: auto;
                }
            }
            .nav-name-request-quote {
                align-self: center;
                margin-left: $baseline / 2;
            }
            .nav-name-request-quote.has-sub-menu {
                & > a {
                    background-color: map-get(get-button-colors(fill), background);
                    color: map-get(get-button-colors(fill), text);
                }
                a::before {
                    background-color: map-get(get-button-colors(fill), background-hover);
                }
            }
        }
        .nav-secondary {
            min-width: 10rem;
        }
        .sub-menu {
            background-color: nth($gray, 1);
            box-shadow: 0 2px 3px -1px rgba($black, .2);
            a,
            .has-sub-menu > a {
                flex-grow: 1;
                border: none;
                padding: $baseline * 2/3 $baseline;
            }
            .sub-menu {
                background-color: nth($gray, 2);
                a {
                    color: $text-color-dark;
                }
            }
        }
        .search-cont {
            align-self: center;
            margin-left: $baseline;
        }
    }
}

@include header-flyin(
    $sizes: ($mobile-breakpoint-down: 40%, medium: 60%, 500px: 100%),
    $underlay-color: rgba($white, 0.8),
    $menu-icon-background-color: $off-white,
    $menu-button-x-offset: $gutter
);

.mobile-menu-expand {
    @include breakpoint($mobile-breakpoint-up up) {
        display: none;
    }
}
.mobile-menu-expand-icon {
    @include menu-button($text-color-regular);
    .open & {
        @include menu-button-open;
    }
}
