@include search-form(
    $inactive-color: $text-color-dark,
    $primary-color: $anchor-color,
    $secondary-color: $anchor-color-hover,
    $text-padding: .5em,
    $icon-box-size: 1.5rem,
    $icon-size: 20px,
    $icon-side: left
);

@include breakpoint($mobile-breakpoint-up up) {
    @include search-form-collapse(
        $primary-color: $text-color-dark,
        $secondary-color: $anchor-color-hover,
        $height: 36px,
        $icon-box-size: 28px,
        $icon-size: 28px,
        $text-padding: $baseline / 2 0px $baseline / 2 .5em,
        $border-width: 0px
    );
}

.search-cont {
    .sayt-cont {
        // height: calc(100vh - #{$baseline * 12.5});
        background-color: $white;
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
        box-shadow: 0 2px 8px -2px rgba(0,0,0,0.3);
        .search-result a,
        .sayt-no-results {
            color: $text-color-regular;
        }
        .search-result a:hover,
        .sayt-selected a {
            background-color: rgba($secondary-color, .2);
        }
        @include breakpoint($mobile-breakpoint-up up) {
            height: auto;
            max-height: 60vh;
        }
    }

    .search-input {
        border: none;
        box-shadow: inset 0 -3px 0 -1px $text-color-regular;
        // padding-top: 2px;
        // padding-bottom: 0;
        // border-bottom: 2px solid $text-color-regular;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
        transition: width 0.5s ease, 
                    padding 0.5s ease, 
                    opacity 0.5s ease, 
                    box-shadow .3s;
        &:focus,
        .search-has-text {
            box-shadow: inset 0 -3px 0 -1px $anchor-color;
            // border-color: $anchor-color;
        }
    }
    @include breakpoint($mobile-breakpoint-down down) {
        .sayt-cont .search-link a,
        .sayt-cont .sayt-no-results,
        .search-input {
            padding-left: calc(.75rem + 8px + 1em);
        }
        .search-input {
            background-color: transparent;
        }
    }
    @include breakpoint($mobile-breakpoint-up up) {
        .search-input:focus,
        .search-has-text .search-input {
            box-shadow: inset 0 -2px 0 0 $anchor-color,
                        0 2px 8px -2px rgba(0,0,0,0.3);
        }
        .sayt-cont {
        }
    }
}

.item-group.search-results {
    @include search-results;
    .paging {
        @include search-paging-buttons;
    }
}
