@import 'site:variables';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/grid';

@import 'site:item-mixins';
@import 'forms:mixins';

.layout-u-value-calculator-page {
    .primary {
        .pager.instructions {
            margin-left: $gutter;
            margin-right: $gutter;
            margin-bottom: 0;
            .item-wrapper {
                margin-bottom: $baseline * 2;
                .title {
                    @include typography(header-c);
                }
            }
        }
    }
}

.u-value-calculator-root {
    @include grid;
    @include subgrid;
    & > * {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    .u-value-form {
        @include grid-row(1);
        @include grid-column(1, 12);
        @include grid-align-self(start);
        @include breakpoint(large up) {
            @include grid-column(1, 8);
        }
    }
    .u-value-results {
        @include grid-row(2);
        @include grid-column(1, 12);
        @include grid-align-self(start);
        @include breakpoint(large up) {
            @include grid-row(1);
            @include grid-column(9, 4);
            max-width: 360px;
        }
    }
}

.u-value-form {
    @include paper;
    padding: $baseline 0;
    overflow: auto;
    thead .title {
        @include typography(header-c);
        color: $text-color-dark;
    }
    .cell-title,
    .cell-value,
    .cell-area {
        width: 33%;
        min-width: 12rem;
    }
    .cell-remove .button {
        min-width: 0;
        white-space: nowrap;
        @include breakpoint(medium down) {
            span {
                display: none;
            }
        }
        &:disabled {
            visibility: hidden;
        }
    }
    .input .input-field:disabled {
        background-color: transparent;
        cursor: default;
    }
}

table.u-value-calc {
    @include form-table;
    tfoot td {
        padding-top: $baseline * 2;
    }
}

.u-value-results-paper {
    @include paper;
    padding: $baseline * 1.5 $baseline * 2;
    background-color: $primary-color;
    color: $text-color-white;
}
.u-value-result-list {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $baseline;
    align-items: baseline;
    margin: 0;
    margin-top: $baseline;
    // dt,
    // dd {
    //     flex-basis: 50%;
    //     margin: 0;
    //     margin-top: $baseline;
    //     padding-bottom: $baseline / 2;
    //     border-bottom: 1px dashed rgba($text-color-white, .5);
    // }
    // dt {
    //     flex-grow: 1;
    //     font-weight: $normal;
    // }
    .u-value-result-head {
        @include typography(header-base);
        font-size: 24rem/$em;
        text-transform: none;
        font-weight: $normal;
    }
    .u-value-result-value {
        @include typography(header-base);
        text-align: right;
        font-size: 42rem/$em;
        line-height: 1;
    }
    .u-value-result-unit,
    .u-value-result-area-unit {
        @include typography(text-medium);
        color: inherit;
    }
    .u-value-result-unit {
        justify-self: stretch;
        grid-column: span 2;
        margin-top: $baseline / -2;
        text-align: right;
        padding-bottom: $baseline / 2;
        border-bottom: 1px dashed rgba($text-color-white, .5);
    }
    .u-value-result-area-unit {
        padding-left: $baseline / 2;
    }
    .u-value-result-area,
    .u-value-result-area ~ * {
        margin-top: $baseline;
        // font-size: 20rem/$em;
    }
    .total-unit {
        font-size: .75em;
    }
}
.u-value-results-unit {
    display: flex;
    justify-content: flex-end;
    padding: 0 $baseline * 2;
    margin-top: $baseline;
    font-weight: $semibold;
    .unit-button {
        color: $primary-color;
        min-width: 1.5em;
        text-align: center;
        margin-left: .5em;
        &:hover {
            text-decoration: underline;
        }
    }
    .is-active {
        text-decoration: underline;
        font-weight: $bold;
    }
}
