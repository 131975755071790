@include rte-content();
@include base-content();
@include rte-lists();
// %rte-bullet {
//     width: .75em;
//     color: $green;
//     font-weight: $bold;
//     transform: scale(1.25);
// }
a {
    transition: color .2s;
}

.rte {
    a {
        text-decoration: underline;
    }
    .button {
        text-decoration: none;
    }
    h2 {
        @include typography(header-c);
    }
    h3 {
        @include typography(header-d);
    }
    
    blockquote {
        font-size: 1rem;
        line-height: 1.75;
        margin-left: 3em;
    }

    table {
        th[scope="row"] {
            vertical-align: top;
            text-align: left;
        }
        @include breakpoint(smallplus down) {
            &,
            tbody,
            tr,
            th,
            td {
                display: block;
            }
        }
    }
    
    .media-container {
        margin-top: $baseline;
    }
}

.rte.small-text {
    @include typography(text-small);
}

ul.rte-columns,
ol.rte-columns {
    column-width: 12rem;
}

