@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

@import 'site:page-mixins';
@import 'site:item-mixins';
@import 'forms:mixins';

@import 'callouts:mixins';

.layout-product-page {
    .callouts-grid {
        padding-top: $baseline * 2;
        border-top: 1px solid $light-gray;
    }
}

.group.product-details {
    @include breakout($section-padding) {
        margin-top: $value;
        & > * {
            margin-top: $value / 2;
        }
    }
    & > * {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    & > .item-wrapper {
        margin-top: 0;
        border-bottom: 1px solid $light-gray;
        padding-bottom: $baseline / 2;
    }
    & > .image {
        @include object-fit((object-fit: contain));
        position: relative;
        margin-top: $baseline * 2;
        background-color: $white;
        height: $baseline * 20;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .details-page,
    .r-values {
        max-width: 600px;
        width: 100%;
        @include grid-justify-self(center);
    }
    .details-page {
        margin-left: 0;
        margin-right: 0;
        & > * {
            margin-top: $baseline * 2;
            margin-left: $gutter;
            margin-right: $gutter;
            &:first-child {
                margin-top: 0;
            }
        }
        .subtitle + .title {
            margin-top: $baseline / 2;
        }
        .subtitle {
            @include typography(header-e);
            color: $primary-color;
        }
        .title {
            @include typography(header-b);
        }
    }
    .r-values {
        margin-left: 0;
        margin-right: 0;
    }
    
    @include breakpoint(medium up) {
        @include grid;
        @include grid-template-columns(1fr 1fr);
        & > .item-wrapper {
            @include grid-row(1);
            @include grid-column(1, 2);
            margin-bottom: $baseline * 2;
        }
        & > .image {
            @include grid-row(2, 2);
            @include grid-column(1);
            height: 100%;
            margin-top: 0;
        }
        .details-page {
            @include grid-row(2);
            @include grid-column(2);
        }
        .r-values {
            @include grid-row(3);
            @include grid-column(2);
        }
        .configuration-grid {
            @include grid-row(4);
            @include grid-column(1, 2);
        }
    }
}

.item-group.r-values {
    display: flex;
    flex-wrap: wrap;
    & > .item-group {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: $gutter;
        margin-right: $gutter;
        margin-top: $gutter;
        flex-grow: 1;
        .item-wrapper {
            order: 2;
        }
    }
    .text {
        @include typography(header-a);
        padding: $baseline * 2 $baseline;
        border: 1px solid;
        white-space: nowrap;
        .sb-inline-editor-first-button {
            font-size: 1rem;
        }
    }
    .item-wrapper .title {
        @include typography(header-d);
        font-weight: $normal;
        margin-top: $baseline / 2;
    }
}
