@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/typography';

@import 'mixins';

@include form-base;

.site {
    @include input-all(
        $base-style: input-material,
        $additional-styles: (
            '.label-placeholder': input-label-placeholder,
            '.switch': input-switch,
            '.checkbox-list li, .radio-buttons li': input-checkbox
        ),
    );
    .button {
        @include button;
    }
    .input-group {
        display: flex;
        align-items: center;
        margin-left: $baseline * -1;
        & > * {
            margin-left: $baseline;
        }
        .input-group-button {
          margin-left: $baseline / 2;
          & > * {
            margin-left: $baseline / 2;
          }
        }
    }
    .image-button.help {
        display: inline-block;
        overflow: hidden;
        width: 1.5rem;
        height: 1.5rem;
        text-indent: -999px;
        background-repeat:no-repeat;
        background-position: center;
        background-size: contain;
        @include sb-icon-help($text-color-regular);
        &:hover,
        &:focus {
            @include sb-icon-help($anchor-color-hover);
        }
    }
}
