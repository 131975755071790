@import "@depot/grid";

@mixin slideshow(
    $nav-button-placeholder: "%slide-nav-default",
    $nav-button-hover-placeholder: "%slide-nav-hover-default"
) {
    @include slideshow-navigation-buttons($nav-button-placeholder, $nav-button-hover-placeholder);
    .transition-fade {
        @include slideshow-animation-fade;
    }
    .transition-push {
        @include slideshow-animation-translate;
    }
}

@mixin slideshow-navigation-buttons(
    $nav-button-placeholder: "%slide-nav-default",
    $nav-button-hover-placeholder: "%slide-nav-hover-default"
) {
    .slideshow-cont {
        position: relative;
    }
    .slideshow-stage {
        position: relative;
        overflow: hidden;
    }
    .slideshow-buttons {
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .slideshow-previous,
    .slideshow-next {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20%;
        position: absolute;
        top: 0%;
        right:0;
        bottom: 0%;
        pointer-events: none;
        // &::before {
        //     content: "";
        //     // z-index: -1;
        //     display: block;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     opacity: 0;
        //     background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.2));
        //     transition: opacity .3s ease;
        // }
        &::after {
            content: "";
            display: block;
            position: relative;
            pointer-events: auto;
            @extend #{$nav-button-placeholder};
        }
    }
    .slideshow-stage:hover {
        .slideshow-buttons {
            display: block;
        }
    }
    .slideshow-next:hover,
    .slideshow-previous:hover {
        cursor: pointer;
        // &::before {
        //     opacity: 1;
        // }
        &::after {
            @extend #{$nav-button-hover-placeholder};
        }
    }
    .slideshow-previous {
        transform: rotate(180deg);
        left: 0;
        right: auto;
    }
    .slideshow-cont.slideshow-single,
    .slideshow-cont.is-touch {
        .slideshow-buttons {
            display: none;
        }
    }
    .slideshow-thumbnail-list {
        ul {
            padding-left: 0;
            line-height: 0;
            li {
                display: inline-block;
            }
            li:hover {
                cursor: pointer;
            }
        }
    }
    %slide-nav-default {
        @include sb-icon-chevron($white);
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        margin: 1rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
        background-color: $dark-gray;
        opacity: .6;
    }
    %slide-nav-hover-default {
        opacity: .9;
    }
}
@mixin slideshow-animation-fade() {
    .items {
        @include grid;
        @include grid-template-rows(1fr);
        @include grid-template-columns(1fr);
    }
    //ie11 has issues with pointer-events not fully disabling, causing inner slide buttons to be blocked
    //so it gets a different transition, that has the downside of the background being briefly visible.
    .slide {
        @include grid-column(1);
        @include grid-row(1);
        @include grid-align-self(stretch);
        z-index: 1;
        transition: opacity .6s ease;
        position: relative;
        opacity: 0;
    }
    .slide.last-slide {
        z-index: 2;
    }
    .slide.current-slide {
        z-index: 3;
        opacity: 1;
    }
    .slideshow-previous,
    .slideshow-next {
        z-index: 4;
    }
    @supports (pointer-events: none) {
        .slide {
            z-index: 1;
            opacity: 1;
        }
        .slide.last-slide {
            z-index: 3;
            opacity: 0;
            pointer-events: none;
        }
        .slide.current-slide {
            z-index: 2;
        }
    }
}
@mixin slideshow-animation-translate() {
    .items {
        will-change: transform;
        display: flex;
    } 
    .item {
        flex-basis: 100%;
        max-width: 100%;
        flex-shrink: 0;
    }
}

@mixin slideshow-navigation-images(
    $thumbnail-size: (
        small: $baseline * 3,
        medium: $baseline * 4,
        large: $baseline * 6
    ),
    $gutter: (
        small: #{2 / $em}rem,
    ),
    $align: center,
    $thumbnail-styles: (transition: filter .1s),
    $current-styles: (filter: brightness(0.8)),
    $hover-styles: (filter: brightness(1.2)),
) {
    text-align: $align;
    ul {
        // padding: $gutter / 8;
        display: inline-flex;
        flex-wrap: wrap;
        line-height: 0;
        @include breakout($gutter) {
            padding: $value;
        }
        @supports (display: grid) {
            display: grid;
            width: 100%;
            justify-content: center;
            @include breakout($thumbnail-size) {
                grid-template-columns: repeat(auto-fit, #{$value});
            }
            @if $align == center {
                justify-content: center;
            } @else if $align == left or $align == start {
                justify-content: start;
            } @else if $align == right or $align == end {
                justify-content: end;
            }
        }
    }
    li {
        @include breakout($gutter) {
            padding: $value;
        }
        @include breakout($thumbnail-size) {
            width: $value;
            height: $value;
        }
        display: inline-flex;
        cursor: pointer;
        img {
            @each $style, $value in $thumbnail-styles {
                #{$style}: #{$value};
            }
        }
        .image {
            @include object-fit;
            display: flex;
        }
        &.current-slide img {
            @each $style, $value in $current-styles {
                #{$style}: #{$value};
            }
        }
        &:hover,
        &:focus {
            img {
                @each $style, $value in $hover-styles {
                    #{$style}: #{$value};
                }
            }
        }
    }
}

@mixin slideshow-navigation-pips(
    $pip-hitbox: 24px,
    $pip-size: 8px,
    $pip-margin: 0,
    $pip-color: rgba($white, .6),
    $pip-color-active: $white,
    $pip-shadow: 0 0 0 1px rgba(0,0,0,0.1),
    $pip-shadow-active: $pip-shadow
) {
    ul {
        display: flex;
        justify-content: center;
    }
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $pip-hitbox;
        height: $pip-hitbox;
        margin: $pip-margin;
        cursor: pointer;
        &::before {
            content: '';
            display: block;
            width: $pip-size;
            height: $pip-size;
            border-radius: 50%;
            background-color: $pip-color;
            box-shadow: $pip-shadow;
            transition: background-color .2s ease,
                        box-shadow .2s ease;
        }
        &.current-slide {
            &::before {
                box-shadow: $pip-shadow-active;
                background-color: $pip-color-active;
            }
        }
    }
}

@mixin slideshow-navigation-line-pips(
    $pip-color: $light-gray,
    $pip-color-active: $anchor-color,
    $pip-color-hover: $anchor-color-hover,
    $align: left
) {
    pointer-events: none;
    ul {
        display: flex;
        flex-wrap: wrap;
        @if $align == center {
            justify-content: center;
        } @else if $align == right {
            justify-content: flex-end;
        }
    }
    li {
        box-sizing: content-box;
        pointer-events: auto;
        cursor: pointer;
        width: 2em;
        height: (2/$em) * 1em;
        background-color: $pip-color;
        background-clip: content-box;
        padding-top: calc(#{$baseline} - #{(1/$em) * 1rem});
        padding-bottom: calc(#{$baseline} - #{(1/$em) * 1rem});
        transition: background-color .3s;
        @if $align == center {
            margin-left: (2/$em) * 1em;
            margin-right: (2/$em) * 1em;
        } @else if $align == right {
            margin-left: (4/$em) * 1em;
        } @else {
            margin-right: (4/$em) * 1em;
        }
        &:hover,
        &:focus {
            background-color: $pip-color-hover;
        }
        &.current-slide {
            background-color: $pip-color-active;
        }
    }
}

