@mixin page-layout-base {
    .primary {
        @include grid;
        @include grid14;
    }
    .header-banner {
        @include hero-banner;
        position: relative;
        z-index: 2;
    }
    .primary {
        position: relative;
        z-index: 1;
        @include make-grid-rows(8);
        @include breakout($section-padding) {
            padding-bottom: $value;
        }
        & > * {
            @include grid-column(1, 12, true);
            @include breakout($section-padding) {
                margin-top: $value;
            }
        }
    }
    .footer {
    }
}

@mixin page-layout-feature {
    .header-banner {
        @include hero-banner-height($height: 85vh, $portrait-height: 50vh, $page-padding: $section-padding);
    }
}

// @mixin modal {
//     @include dialog-window();

//     .site-dialog {
//         // width: calc(100% - #{$gutter * 2});
//         // max-width: 880px;
//         border: none;
//         width: -moz-max-content;
//         @include breakpoint(700px down) {
//             height: calc(100% - 24px);
//             max-height: none;
//         }
//         .dialog-close {
//             z-index: 10;
//             padding: $baseline / 3 $baseline;
//             border-radius: $baseline * 2;
//             background-color: $white;
//             top: $baseline / 2;
//             right: $baseline / 2;
//         }
//     }
// }

@mixin modal-open {
    overflow: hidden;
    // body {
    //     overflow: scroll;
    // }
}
