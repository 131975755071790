@mixin page-layout-portfolio-page {
    .primary {
        position: relative;
        .breadcrumbs {
            z-index: 2;
            position: relative;
            @include grid-row(1);
            @include grid-column(1, 14);
            @include grid-align-self(start);
            @include grid-justify-self(start);
            @include breakout($outer-gutters) {
                margin-left: $value + $gutter;
                margin-right: $value + $gutter;
            }
            // @include breakout($section-padding) {
            //     padding-top: $value / 2;
            // }
            margin-top: 0;
            padding-top: $baseline * 2;
            color: $black;
            text-shadow: 0 0 6px $white;
        }
        #display-point-edit-primary-slideshow-.sb-display-point-first {
            z-index: 1;
            position: relative;
            @include grid-row(1);
            @include grid-column(1, 14);
            @include grid-align-self(start);
            margin-top: 0;
            min-height: 300px;
            background-color: rgba(0,0,0,0.5);
            color: $text-color-white;
        }
        .case-study-slideshow {
            z-index: 1;
            position: relative;
            @include grid-column(1, 14);
            @include grid-row(1);
            @include grid-align-self(start);
            margin-top: 0;
        }
        .case-study-content {
            @include grid-row(2);
            @include grid-column(1, 12, true);
            margin-left: $gutter;
            margin-right: $gutter;
        }
        @include breakpoint(large up) {
            .breadcrumbs,
            .case-study-slideshow {
                position: sticky;
                @include breakout($header-height) {
                    top: $value;
                }
            }
            #display-point-edit-primary-slideshow-.sb-display-point-first,
            .case-study-slideshow {
                @include grid-column(1, 8);
                margin-bottom: 0;
            }
            .case-study-content {
                @include grid-row(1);
                @include grid-column(9, 5);
                margin-left: $gutter * 3;
                margin-bottom: 0;
            }
        }
        @include breakpoint(xxlarge up) {
            #display-point-edit-primary-slideshow-.sb-display-point-first,
            .case-study-slideshow {
                @include grid-column(1, 9);
            }
            .case-study-content {
                @include grid-row(1);
                @include grid-column(10, 4);
                margin-left: $gutter * 3;
            }
        }
    }
}

@mixin case-study-content {
    & > * {
        margin-top: $baseline * 2;
        @include breakpoint(medium up) {
            margin-top: $baseline * 3;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    h2.subtitle {
        @include typography(header-e);
        color: $primary-color;
        & ~ .title {
            margin-top: 0;
        }
    }
    h1.title {
        @include typography(header-b);
        text-transform: none;
    }
    .group.details {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        margin-top: 0;
        margin-right: -1.5rem;
        margin-top: $baseline;
        @include breakpoint(medium up) {
            margin-top: $baseline * 2;
        }
        h2.title {
            @include typography(header-d);
        }
        & > * {
            margin-top: $baseline;
            flex-basis: 10rem;
            flex-grow: 1;
            padding-right: 1.5rem;
        }
        .pager.client {
            order: 4;
        }
        .pager.location {
            order: 3;
        }
        .pager.product {
            order: 2;
        }
        .pager.completion_date {
            order: 1;
        }
    }
    .pager.client {
        @include rte-content($baseline: $baseline / 4);
        h2, h3 {
            @include typography(header-e);
            color: $primary-color;
        }
        .rte table td {
            padding: $baseline / 2 0 0;
        }
    }
}
