html {
    @include breakout($header-height) {
        --header-height: #{$value};
    }
    scroll-behavior: smooth;
}
:target {
    scroll-margin-top: var(--header-height);
}

html.modal-open,
html.navigation-open {
    @include modal-open;
}

.site {
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    background-color: $content-background-color;
    // @include breakout($header-height) {
    //     padding-top: $value;
    // }
}

@include page-layout-base;

.layout-product-page {
    @include page-layout-feature;
}

.layout-clip-calculator-page {
    .item-group.calculator {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    @include breakpoint(xxlarge up) {
        .primary > * {
            @include grid-column(2, 10, true);
        }
        .item-group.callouts-alternating {
            @include grid-column(1, 14);
        }
    }
}

// @include modal;
@include dialog-window;
.dialog.tooltip {
    @include dialog-tooltip;
    @include dialog-positioned;
    font-size: (14rem/$em);
    dd,
    dt {
        font-size: (14rem/$em);
    }
}

