@import 'parent:';

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1700px,
);

$mobile-breakpoint-up: xlarge;
$mobile-breakpoint-down: large;

$header-height: (
    small: $baseline * 5,
    medium: $baseline * 6,
);

$header-logo-height: (
    small: $baseline * 2,
    medium: $baseline * 3
);

$outer-gutters: (
    small: $gutter * 1.5,
    medium: $gutter * 4,
    // large: $gutter * 6,
    xlarge: $gutter * 10,
    xxlarge: $gutter * 15
);

$section-padding: (
    small: $baseline * 3,
    medium: $baseline * 4,
    large: $baseline * 6,
    xxlarge: $baseline * 8,
    // xxlarge: $baseline * 10
);

$site-width: 1600px;
$site-columns: 12;

$orange: #f79727;
$orange-dark: #ed8409;
$blue: #2161a1;
$red: #e23e2d;

//stock scale
$gray50: #f8f8f8;
$gray100: #f0f0f0;
$gray200: #e5e5e5;
$gray300: #d4d4d4;
$gray400: #b0b0b0;
$gray500: #8f8f8f;
$gray600: #686868;
$gray700: #545454;
$gray800: #363636;
$gray900: #161616;

//new scale
$gray: (
    #f4f7f8,
    #dcdadc,
    #716f76,
    #414141
);

$off-white: nth($gray, 1);
$light-gray: nth($gray, 2);
$medium-gray: nth($gray, 3);
$dark-gray: nth($gray, 4);

$alert-color: $red;
$required-color: $red;

$primary-color: $orange;
$primary-color-dark: $orange-dark;
$secondary-color: $orange;

$text-color-regular: $medium-gray;
$text-color-dark: $dark-gray;

$anchor-color: $orange;
$anchor-color-hover: $orange-dark;

$anchor-color-white: $text-color-white;
$anchor-color-white-hover: $orange;

$content-background-color: $off-white;
$paper-background-color: $white;
$header-background-color: $white;
$footer-background-color: $dark-gray;
$footer-text-color: $light-gray;

$body-font-family: 'Verdana', sans-serif;
$header-font-family: 'trade-gothic-next-condensed', sans-serif;

$light: 100;
$normal: 400;
$semibold: 500;
$bold: 700;

$available-icons: facebook twitter email phone linkedin;

$input-props: (
    vertical-margin: $baseline * 1.5,
    input-border-radius: 0,
    input-line-height: 1.25em,
    input-padding: .75em .75em .625em,
    input-border: (2/$em) * 1rem,
    input-background-color: $gray200,
    input-border-color: $gray400,
    input-border-focus-color: $anchor-color,
    button-min-width: 8em,
    button-padding: .75em .75em,
    // button-border: 1px solid
);

$button-colors: (
    base: (
        background: $medium-gray,
        text: $text-color-white,
        border: $medium-gray,
        background-hover: $dark-gray,
        text-hover: $text-color-white,
        border-hover: $dark-gray,
    ),
    fill: (
        background: $medium-gray,
        text: $text-color-white,
        border: $medium-gray,
        background-hover: $dark-gray,
        text-hover: $text-color-white,
        border-hover: $dark-gray,
    ),
    text-only: (
        background: transparent,
        text: inherit,
        border: transparent,
        background-hover: transparent,
        text-hover: $anchor-color,
        border-hover: transparent,
    ),
    outline: (
        background: transparent,
        text: $medium-gray,
        border: $medium-gray,
        background-hover: transparent,
        text-hover: $dark-gray,
        border-hover: $dark-gray,
    ),
    white: (
        background: $text-color-white,
        text: $medium-gray,
        border: $text-color-white,
        background-hover: $off-white,
        text-hover: $medium-gray,
        border-hover: $off-white,
    ),
    white-outline: (
        background: transparent,
        text: $text-color-white,
        border: $text-color-white,
        background-hover: rgba($text-color-white, .15),
        text-hover: $text-color-white,
        border-hover: $text-color-white,
    )
);

$typography: (
    header-base: (
        font-weight: $bold,
        font-family: $header-font-family,
        line-height: 1,
        color: inherit,
        text-transform: uppercase,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
        font-family: $body-font-family,
        color: $text-color-regular,
    ),
    header-e: (
        base: header-base,
        font-size: #{14/$em}rem,
        line-height: #{18/$em}rem,
    ),
    header-d: (
        base: header-base,
        font-size: #{18/$em}rem,
        line-height: #{24/$em}rem,
    ),
    header-c: (
        base: header-base,
        font-size: #{20/$em}rem,
        line-height: #{24/$em}rem,
        // color: $text-color-dark,
        medium: (
            font-size: #{24/$em}rem,
            line-height: #{30/$em}rem,
        )
    ),
    header-b: (
        base: header-base,
        font-size: #{24/$em}rem,
        medium: (
            font-size: #{30/$em}rem,
        ),
        xlarge: (
            font-size: #{48/$em}rem,
        ),
    ),
    header-a: (
        base: header-base,
        font-size: #{30/$em}rem,
        medium: (
            font-size: #{48/$em}rem,
        ),
        xlarge: (
            font-size: #{56/$em}rem,
        ),
    ),
    text-small: (
        base: text-base,
        font-size: #{14/$em}rem,
        line-height: #{20/$em}rem,
        // xlarge: (
        //     font-size: #{14/$em}rem,
        //     line-height: #{20/$em}rem,
        // )
    ),
    text-medium: (
        base: text-base,
        font-size: #{16/$em}rem,
        line-height: 1.5,
    ),
    text-large: (
        base: text-base,
        color: inherit,
        font-size: #{20/$em}rem,
        line-height: 1.75,
        // smallplus: (
        //     font-size: #{18/$em}rem,
        // ),
        // xlarge: (
        //     font-size: #{24/$em}rem,
        //     line-height: #{42/$em}rem,
        // ),
    ),
    // text-xlarge: (
    //     base: text-base,
    //     color: inherit,
    //     font-size: #{18/$em}rem,
    //     line-height: #{30/$em}rem,
    //     xlarge: (
    //         font-size: #{30/$em}rem,
    //         line-height: #{48/$em}rem,
    //     ),
    // ),
);

$article-props: (
    compact-breakpoint: small,
    content-width: 900px,
    image-size: (
        small: $baseline * 4,
        smallplus: $baseline * 5,
        medium: (68rem / $em),
    ),
    // title-typography: header-d,
    summary-typography: text-medium,
    // head-padding: 0,
);

$article-icons-props: (
    // size: 1em,
    // background-size: 1em,
    hover-color: $anchor-color
);

$email-button-palette: base;
$email-button-props: (
    padding: $baseline
);
