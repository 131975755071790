@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

@import 'site:item-mixins';
@import 'forms:mixins';

@import 'callouts:mixins';

.nav-overview {
    margin-left: $gutter;
    margin-right: $gutter;
    @include callouts-grid-item;
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2,
                large: 3,
                xxlarge: 5
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true,
        );
    }
}
