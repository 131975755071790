$mobile-breakpoint-up: medium !default;
@mixin contact-layout (
) {
    .primary {
        padding-left: 0;
        padding-right: 0;
        @include grid-gap($gutter * 2);
        .item-group.left,
        .item-group.right {
            & > *:first-child {
                margin-top: 0;
            }
        }
        .item-wrapper {
            margin-bottom: $baseline / 2;
        }
        @include breakpoint(smallplus only) {
            @include contact-layout-compact;
        }
        @include breakpoint(medium up) {
            @include grid;
            @include subgrid;
            @include grid-template-rows(auto 1fr);
            .item-group.page {
                @include grid-row(1);
                @include grid-column(1, 5);
                margin-left: 0;
            }
            .item-group.left {
                @include grid-row(2);
                @include grid-column(1, 5);
            }
            .item-group.right {
                @include grid-row(1, 2);
                @include grid-column(1, 12);
            }
            .page ~ .right,
            .left ~ .right {
                @include grid-column(6, 7);
            }
        }
        @include breakpoint(large up) {
            .item-group.page,
            .item-group.left {
                @include grid-column(1, 4);
            }
            .page ~ .right,
            .left ~ .right {
                @include grid-column(5, 8);
            }
        }
    }
    .secondary + .primary {
        @include breakpoint($mobile-breakpoint-up up) {
            display: block;
        }
    }
}
@mixin contact-layout-compact {
    @supports (display: contents) {
        display: grid;
        grid-template-columns: 1fr 1.25fr;
        .item-group.page {
            grid-column-end: span 2;
        }
        .item-group.left {
            grid-column-end: span 1;
        }
        .item-group.right {
            @include grid-gap($gutter * 2);
            display: contents;
            & > *:first-child {
                margin-top: $baseline * 2;
            }
        }
        .map {
            // height: 100%;
            margin-left: $gutter;
            margin-right: $gutter;
            grid-column-end: span 2;
        }
        .left + .right .map {
            grid-column-end: span 1;
        }
        .directions {
            grid-column-end: span 2;
        }
        .contact-form {
            grid-column-end: span 2;
        }
    }
}
@mixin contact-map(
    $height: (
        small: $baseline * 20
    )
) {
    position: relative;
    // width: 100%;
    @include breakout($height) {
        height: $value;
    }
    iframe,
    .sb-display-point-edit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
@mixin contact-form (
    $form-icon-color: $light-gray,
    $baseline: $baseline * 2,
    $gutter: $baseline,
    $shrink-wrap: true
) {
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @if $shrink-wrap {
            margin-top: -#{$baseline};
        }
    }
    .input,
    .button,
    .g-recaptcha {
        margin-top: $baseline;
    }
    .input {
        flex-basis: 100%;

        input,
        textarea {
            // padding: $baseline;
            // padding-right: 2em;
            // background-size: 1.25em;
            // background-position: 98% 0.45em;
            // background-position: right 0.5em top 0.45em;

        }

        textarea {
            resize: vertical;
        }
    }
    .button {
        flex-basis: 100%;
    }
    @include breakpoint(smallplus up) {
        .button {
            flex-basis: 50%;
        }
    }
    @include breakpoint(medium up) {
        .input:first-child {
          //  padding-right: 1rem; //can't use padding if we want to put the icon line on with a pseudo element
            margin-right: $gutter;
        }
        .input:first-child,
        .input:nth-child(0n + 2) {
            flex-basis: calc(50% - #{$gutter});
            flex-grow: 1;
        }
    }
    @include breakpoint(large up) {
        .button {
            flex-basis: auto;
        }
    }

    .input-name input {
        @include sb-icon-user($form-icon-color); 
    }

    .input-email input {
        @include sb-icon-email($form-icon-color);
    }

    .input-phone input {
        @include sb-icon-phone($form-icon-color);
    }

    .input-message textarea {
        @include sb-icon-message($form-icon-color);
    }

    .recaptcha-and-button {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & > * {
            margin-top: $baseline * 2/3;
        }

        .invisible {
            display: none;
        }
    }
    .g-recaptcha,
    .recaptcha-custom-terms {
        padding-right: $baseline / 2;
        margin-right: auto;
    }
    .g-recaptcha {
        flex-shrink: 0;
    }

    .recaptcha-custom-terms {
        font-size: (11/$em) * 1rem;
        color: $text-color-light;
    }
}

@mixin recaptcha {
    @warn "form recaptcha mixin styles are now integrated into contact form";
}
