@import 'site:variables';
@import '@depot/grid';
@import 'mixins';

.group.expander-cont {
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    & > .title {
        flex-grow: 1;
        align-self: flex-end;
        margin-bottom: $baseline / 2;
    }
    .categories-select {
        .input {
            margin: 0;
        }
    }
    .expander-list {
        flex-basis: calc(100% - #{$gutter * 2});
        &:not(:first-child) {
            margin-top: $baseline;
        }
    }
    // @include breakpoint(xlarge up) {
    //     @include grid-column(2, 10, true);
    // }
}

.lister.article-list,
.lister.expander-list {
    @include expander-list;
}
