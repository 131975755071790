.footer {
    background-color: $footer-background-color;
    color: $footer-text-color;
    padding-top: $baseline * 1.5;
    padding-bottom: $baseline * 2;
    box-shadow: 0 5rem 0 $footer-background-color;
    @include breakout($outer-gutters) {
        padding-left: $value + $gutter;
        padding-right: $value + $gutter;
    }
    a {
        color: inherit;
        &:hover,
        &:focus {
            text-decoration: underline;
            color: $text-color-white;
        }
    }
    .contact-social {
        display: flex;
        font-size: 2rem;
        a {
            display: flex;
            margin-right: .25em;
        }
    }
    .footer-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > * {
            margin-top: $baseline / 2;
        }
        &:nth-child(2) {
            padding-top: $baseline / 2;
            margin-top: $baseline * 1.5;
            border-top: 1px solid;
        }
    }
    .footer-nav-left,
    .footer-nav-right {
        @include typography(text-small);
        color: inherit;
        & > * {
            display: inline-block;
        }
    }
    .footer-nav-left > * {
        margin-right: .5em;
        &:not(:last-child) {
            padding-right: .5em;
            border-right: 1px solid $footer-text-color;
        }
    }
    .footer-nav-right > *:not(:first-child) {
        margin-left: .5em;
        padding-left: .5em;
        border-left: 1px solid $footer-text-color;
    }
    @include breakpoint(medium up) {
        padding-top: $baseline * 3.5;
        padding-bottom: $baseline * 4;
        .footer-row:nth-child(2) {
            padding-top: $baseline * 1.5;
            margin-top: $baseline * 2.5;
        }
    }
}

