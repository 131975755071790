@import 'parent:';

@mixin _psuedo-arrow($color) {
    content: '';
    display: inline-block;
    width: 1.5em;
    height: .75em;
    // vertical-align: -.125em;
    transform-origin: center;
    @include sb-icon-long-arrow($color);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin button {
    @include button-color(base);
    font-family: $header-font-family;
    font-weight: $bold;
    text-transform: uppercase;
}

@mixin button-arrow($arrow-color, $side: end) {
    @if $side == end {
        &::after {
            @include _psuedo-arrow($arrow-color);
            margin-left: .5em;
        }
    } @else {
        &::before {
            @include _psuedo-arrow($arrow-color);
            margin-right: .5em;
            transform: rotate(180deg);
        }
    }
}

@mixin form-table(
    $outer-padding: $baseline * 2,
    $cell-padding: $baseline / 2
) {
    width: 100%;
    tbody tr:nth-child(odd) {
        background-color: $off-white;
    }
    th,
    td,
    .data-subset-animator {
        padding: $cell-padding;
    }
    th,
    td {
        text-align: left;
        &:first-child {
            padding-left: $outer-padding;
        }
        &:last-child {
            padding-right: $outer-padding;
        }
    }
    .td-animator.td-animator {
        padding: 0;
        &:first-child .data-subset-animator {
            padding-left: $outer-padding;
        }
        &:last-child .data-subset-animator {
            padding-right: $outer-padding;
        }
    }
    .input {
        margin-top: 0;
        select.input-field {
            width: 100%;
            min-width: 10rem;
        }
    }
    .input-group > .input {
        flex-grow: 1;
    }
}

@mixin form-untable($outer-padding: $baseline * 2) {
    &,
    tbody,
    tr,
    th,
    td {
        display: block;
    }
    td,
    th,
    .data-subset-animator {
        padding-left: $baseline * 2;
        padding-right: $baseline * 2;
    }
}