@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/grid';

@import 'site:item-mixins';
@import 'forms:mixins';

.layout-nafs-calculator-page {
    .primary {
        .pager.disclaimer {
            margin-left: $gutter;
            margin-right: $gutter;
            margin-bottom: 0;
            .item-wrapper {
                margin-bottom: $baseline * 2;
                .title {
                    @include typography(header-c);
                }
            }
        }
    }
}

.item-group.nafs-calculator {
    
}

.nafs-disclaimer-checkbox {
    margin-left: $gutter;
    margin-right: $gutter;
    .input-checkbox {
        @include apply-input-mixin(input-checkbox input-checkbox-colors, (
            checkbox-font-size: 1.125rem,
        ));
    }
}
.nafs-container {
    @include grid;
    @include subgrid;
    @include breakout($section-padding) {
        margin-top: $value / 2;
    }
    position: relative;
    & > * {
        margin: $gutter;
    }
    .nafs-disclaimer-block {
        margin: 0;
    }
    .nafs-select-form {
        @include grid-row(1);
        @include grid-column(1, 12);
    }
    .nafs-calculations {
        @include grid-row(2);
        @include grid-column(1, 12);
    }
    .nafs-form-text {
        display: none;
        max-width: 25rem;
    }
    .nafs-performance-results {
        @include grid-row(3);
        @include grid-column(1, 12);
        max-width: 25rem;
    }
    .nafs-uvalue-results {
        @include grid-row(4);
        @include grid-column(1, 12);
        max-width: 25rem;
    }
    .nafs-explanatory-text {
        @include grid-row(5);
        @include grid-column(1, 12);
    }
    @include breakpoint(600px up) {
        .nafs-performance-results {
            @include grid-row(3);
            @include grid-column(1, 6);
        }
        .nafs-uvalue-results {
            @include grid-row(3);
            @include grid-column(7, 6);
        }
        .nafs-explanatory-text {
            @include grid-row(4);
            @include grid-column(1, 12);
        }
    }
    @include breakpoint(medium up) {
        .nafs-select-form {
            @include grid-column(1, 7);
        }
        .nafs-form-text {
            @include grid-row(1);
            @include grid-column(8, 5);
            @include grid-align-self(start);
            display: block;
        }
    }
    @include breakpoint(large up) {
        @include grid-template-rows(auto auto 1fr);
        .nafs-calculations {
            @include grid-row(2, 2);
            @include grid-column(1, 7);
        }
        .nafs-performance-results {
            @include grid-row(2);
            @include grid-column(8, 5);
            @include grid-align-self(start);
        }
        .nafs-uvalue-results {
            @include grid-row(3);
            @include grid-column(8, 5);
            @include grid-align-self(start);
        }
    }
}

.nafs-explanatory-text {
    display: none;
}

.nafs-disclaimer-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    /* production */
    background-color: rgba($content-background-color, .75);

    /* dev */
    // outline: 3px solid red;
    // pointer-events: none;
}

.nafs-section {
    @include paper;
    h2.title {
        @include typography(header-c);
        padding: $baseline * 1.5 $baseline * 1.5 0;
    }
    .nafs-label-cell {
        width: 100%;
    }
    .result-field,
    .result-unit {
        white-space: nowrap;
    }
    .result-help {
        width: 1px;
    }
}

.nafs-form-text {
    padding: $baseline / 2 $baseline * 1.5;
    box-shadow: none;
    p {
        margin: $baseline 0;
    }
}

.nafs-table {
    @include form-table($baseline * 1.5);
    margin: $baseline 0;
}

.nafs-select-table {
    tbody tr:first-child {
        background-color: transparent;
    }
    @include breakpoint(medium down) {
        @include form-untable;
        td:not(.td-animator),
        td .data-subset-animator {
            padding-top: 0;
            padding-bottom: $baseline;
        }
    }
    @include breakpoint(large up) {
        th.title {
            width: 15rem;
        }
    }
}

.nafs-calculations-table {
    @include breakpoint(smallplus down) {
        font-size: (14rem/$em);
    }
}

.nafs-final-results {
    background-color: $primary-color;
    color: $text-color-dark;
    .image-button.help {
        @include sb-icon-help($text-color-dark);
        &:hover,
        &:focus {
            @include sb-icon-help($text-color-regular);
        }
    }
    table {
        margin: $baseline;
        width: calc(100% - #{$baseline * 2});
        font-size: (14rem/$em);
        line-height: 1.25;
    }
    th,
    td {
        text-align: left;
        padding: $baseline / 4 $baseline / 2;
    }
    @include breakpoint(smallplus down) {
        tr {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
        }
        .nafs-label-cell {
            grid-row: 1;
            grid-column: 1 / span 2;
        }
        .result-help {
            grid-row: 1;
            grid-column: 3;
            width: auto;
        }
        .result-field {
            grid-column: 1;
        }
        .result-unit {
            grid-column: 2;
        }
    }
}
