@import 'parent:';

@mixin slideshow-enhance($object-fit: cover) {
    position: relative;
    .item,
    .head,
    .image,
    .images {
        display: flex;
        flex-grow: 1;
    }
    .image,
    .images {
        @include object-fit((object-fit: $object-fit));
    }
}

@mixin split-slideshow($height: 65vh, $portrait-height: 50vh) {
    @include slideshow;
    @include slideshow-size($height, $portrait-height);
    @include slideshow-double-image;
    @include slideshow-enhance;
}

@mixin slideshow-size($height, $portrait-height: $height) {
    .head {
        @include breakout($height) {
            height: $value;
        }
        @media (orientation: portrait) {
            @include breakout($portrait-height) {
                height: $value;
            }
        }
    }
}

@mixin slideshow-double-image {
    .images {
        //max-width is required to get FF to play nice
        .image {
            flex-grow: 1;
            width: calc(#{percentage(2/3)} - #{$gutter / 2});
            max-width: calc(#{percentage(2/3)} - #{$gutter / 2});
            flex-basis: calc(#{percentage(2/3)} - #{$gutter / 2});
        }
        .image + .image {
            width: calc(#{percentage(1/3)} - #{$gutter / 2});
            max-width: calc(#{percentage(1/3)} - #{$gutter / 2});
            flex-basis: calc(#{percentage(1/3)} - #{$gutter / 2});
            margin-left: $gutter;
        }
        @include breakpoint(medium up) {
            .image {
                width: calc(#{percentage(2/3)} - #{$gutter});
                max-width: calc(#{percentage(2/3)} - #{$gutter});
                flex-basis: calc(#{percentage(2/3)} - #{$gutter});
            }
            .image + .image {
                width: calc(#{percentage(1/3)} - #{$gutter});
                max-width: calc(#{percentage(1/3)} - #{$gutter});
                flex-basis: calc(#{percentage(1/3)} - #{$gutter});
                margin-left: $gutter * 2;
            }
        }
        .image:first-child:nth-last-child(1) {
            max-width: 100%;
            width: 100%;
            flex-basis: 100%;
        }
    }       
}
