@import 'parent:';

@mixin expander-list {
    @include article-icons();
    @include basic-article-list();
    @include article-expander-enhance();
    $props: get-article-props();
    .item {
        border-top: 1px solid $text-color-regular;
    }
    // .head-after,
    // .expander .head::after {
    //     align-self: flex-start;
    // }
    .head {
        .image {
            svg {
                fill: $medium-gray;
            }
        }
        .title {
            text-transform: none;
        }
        .file-statistics {
            font-size: .75em;
            font-weight: $normal;
        }
    }
    .show {
        .button {
            margin-top: $baseline;
            margin-right: $baseline;
        }
    }
    .head-link:hover,
    .head-link:focus {
        .head .title {
            color: $anchor-color-hover;
        }
    }
    .expander-bodyu > .video-cont {
        max-width: none;
    }
    @include breakpoint(smallplus up) {
        @supports (display: contents) {
            .expander .head {
                $head-padding: map-get($props, head-padding);
                $article-baseline: map-get($props, baseline);
                .image {
                    grid-row: 1;
                    align-self: center;
                    @include breakout($head-padding) {
                        margin-top: -1 * ($value - $article-baseline);
                        margin-bottom: -1 * ($value - $article-baseline);
                    }
                }
            }
        }
    }
}
