@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

// @import 'site:item-mixins';
@import 'forms:mixins';

@import 'callouts:mixins';

.item-group.compact-downloads,
.item-group.filtered-downloads {
    @include callouts-grid-item;
    margin-left: $gutter;
    margin-right: $gutter;
    .item-wrapper {
        .title {
            @include typography(header-c);
        }
        margin-bottom: $baseline;
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                smallplus: 2,
                large: 3,
                xlarge: 4,
                xxlarge: 5
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true,
        );
    }
    .head {
        .title {
            margin-top: 0;
        }
        .download-list {
            display: flex;
            flex-wrap: wrap;
        }
        .download-item {
            &:not(:last-child)::after {
                content: '/';
                margin-left: .5rem;
                margin-right: .5rem;
            }
            a {
                color: inherit;
                text-decoration: underline;
                text-transform: uppercase;
                &:hover,
                &:focus {
                    color: $anchor-color;
                }
            }
        }
    }
}

.item-group.filtered-downloads {
    @include callouts-grid-item-diagram(small);
    @include breakpoint(small down) {
        .image {
            margin: $baseline * 1.5 0;
            align-self: flex-start;
        }
    }
    .head {
        .title {
            @include typography(header-c);
        }
        .subtitle {
            @include typography(header-d);
            text-transform: none;
            color: $primary-color;
        }
    }
}
.item-group.compact-downloads {
    @include callouts-grid-item-diagram(false);
    @include callouts-grid-item-diagram-row();
    .image {
        margin: $baseline * 1.5 0;
        align-self: flex-start;
    }
    .head {
        .title {
            @include typography(header-d);
        }
        .subtitle {
            @include typography(header-e);
            text-transform: none;
            color: $primary-color;
        }
    }
}

.product-downloads {
    @extend .filtered-downloads;
}
