@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/utility';
@import '@depot/grid';
@import '@depot/breakpoint';
@import '@depot/typography';
@import '@depot/flex-columns';

@import 'site:item-mixins';
@import 'forms:mixins';

@import 'mixins';

.callouts-grid {
    @include callouts-grid;
    margin-left: $gutter;
    margin-right: $gutter;
}

.callouts-alternating {
    @include grid-column(1, 14);
    @include callouts-alternating;
    @include breakpoint(medium up) {
        margin-top: 0;
    }
}

.callouts-testimonials {
    @include callouts-testimonials;
    margin-left: $gutter;
    margin-right: $gutter;
}

.callouts-portfolio {
    @include grid;
    @include grid14;
    @include grid-column(1, 14);
    @include callouts-portfolio;
    & > * {
        @include grid-column(1, 14);
        @include grid-row(2);
    }
    & > .item-wrapper {
        @include grid-column(1, 12, true);
        @include grid-row(1);
        margin-left: $gutter;
        margin-right: $gutter;
    }

}

.item-group.configuration-grid {
    @include callouts-grid-item;
    @include callouts-grid-item-diagram;
    .item-wrapper {
        .title {
            @include typography(header-c);
        }
        margin-bottom: $baseline;
    }
}

.item-group.configuration-grid {
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                smallplus: 2,
                medium: 3,
                large: 5
            ),
            $baseline: $baseline,
            $gutter: $baseline,
            $shrink-wrap: true,
        );
        justify-content: center;
    }
    .head {
        justify-content: center;
        text-align: center;
    }
    .image + .text-group {
        text-align: left;
    }
    @include breakpoint(smallplus up) {
        .image + .text-group {
            text-align: center;
        }
    }
}
