@mixin finish-gallery(
    $image-height: (
        small: $baseline * 20,
        large: $baseline * 30,
        // large: $baseline * 40
    )
) {
    @include grid;
    @include grid14;
    &::before {
        @include grid-row(1);
        @include grid-column(1, 14);
        content: '';
        display: block;
        background-color: $white;
    }
    .finish-stage {
        @include grid-row(1);
        @include grid-column(1, 12, true);
        @include object-fit((object-fit: contain));
        @include breakout($image-height) {
            height: $value;
        }
        position: relative;
        margin: $baseline * 2 $gutter;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .finish-navigation {
        @include grid-row(2);
        @include grid-column(1, 12, true);
        @include grid-align-self(start);
        display: flex;
        flex-direction: column;
        margin: $baseline * 2 $gutter 0;
    }
    .finish-categories {
        margin-top: $baseline / -2;
    }
    .finish-category {
        @include typography(header-d);
        margin-right: 1rem;
        margin-top: $baseline / 2;
        border-bottom: 1px solid transparent;
        outline-offset: 4px;
        color: $text-color-regular;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: $anchor-color;
        }
        &.is-active {
            color: $text-color-regular;
            border-color: $text-color-regular;
        }
    }
    .finish-swatches {
        // display: flex;
        // flex-wrap: wrap;
        margin-left: $gutter / -2;
        margin-right: $gutter / -2;
        margin-top: $baseline / 2;
        flex-grow: 1;
        @include flex-columns(
            $per-row: (
                small: 2,
                smallplus: 3
            ),
            $gutter: $gutter / 2,
            $baseline: $baseline / 2,
        );
        // & > * {
        //     flex-basis: calc(#{percentage(1/3)} - #{$gutter});
        //     margin: $gutter / 2;
        // }
    }
    .finish-swatch {
        display: flex;
        align-items: flex-end;
        padding: $baseline;
        max-height: 50%;
        border: 1px solid rgba(0,0,0,0.05);
        @include typography(header-base);
        text-align: left;
        font-size: (12rem / $em);
        line-height: 1;
        box-shadow: 0 0 0 $primary-color;
        transition: box-shadow .3s;
        &:focus {
            // outline: none;
            // border-color: $primary-color;
        }
        &.is-active {
            box-shadow: 0 4px 0 $primary-color;
            outline: none;
        }
    }
    .finish-disclaimer {
        @include typography(text-small);
        margin-top: $baseline * 1.5;
        a {
            display: inline-block;
        }
    }
    @include breakpoint(smallplus up) {
        .finish-swatchs > * {
            flex-basis: calc(#{percentage(1/3)} - #{$gutter});
        }
    }
    @include breakpoint(medium up) {
        &::before {
            @include grid-column(1, 5);
            margin-right: $gutter;
        }
        .finish-stage {
            @include grid-column(1, 4, true);
            margin-right: $gutter * 2;
        }
        .finish-navigation {
            @include grid-row(1);
            @include grid-column(5, 8, true);
            @include grid-align-self(stretch);
            margin-bottom: $baseline;
        }
    }
    @include breakpoint(large up) {
        &::before {
            @include grid-column(1, 7);
            margin-right: $gutter * 1.5;
        }
        .finish-stage {
            @include grid-column(1, 6, true);
            margin-right: $gutter * 3;
        }
        .finish-navigation {
            @include grid-column(7, 6, true);
            margin-left: $gutter * 1.5;
        }
    }
    @include breakpoint(xlarge up) {
        .finish-swatch {
            font-size: (14rem/$em);
        }
    }
}

@mixin finish-gallery-alternate {
    @include breakpoint(medium up) {
        &::before {
            @include grid-column(10, 5);
            margin-right: 0;
            margin-left: $gutter;
        }
        .finish-stage {
            @include grid-column(9, 4, true);
            margin-right: $gutter;
            margin-left: $gutter * 2;
        }
        .finish-navigation {
            @include grid-row(1);
            @include grid-column(1, 8, true);
        }
    }
    @include breakpoint(large up) {
        &::before {
            @include grid-column(8, 7);
            margin-left: $gutter * 1.5;
        }
        .finish-stage {
            @include grid-column(7, 6, true);
            margin-left: $gutter * 3;
        }
        .finish-navigation {
            @include grid-column(1, 6, true);
            margin-left: $gutter;
            margin-right: $gutter * 1.5;
        }
    }
}
